<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	<div class="page-desc">
		资产折旧-平均年限法计算公式		<br />
	      月折旧率=（1-预计净残值率）/预计折旧期限（月）*100% <br />
	      月折旧额 = 固定资产原值 * 月折旧率
	 </div> 
	 <el-tabs v-model="active" @tab-click="handleClick">
	   <el-tab-pane label="分类折旧汇总表" :name="1"></el-tab-pane> 
	   <el-tab-pane label="资产折旧明细表" :name="2"></el-tab-pane>
	 </el-tabs>
	   <el-form ref="form" :inline="true"  :model="search_key" prop="">
	       <el-form-item label="">
	           <el-input v-model="search_key.keyword" placeholder="请输入关键字搜索" style="width:200px;"></el-input>
	       </el-form-item>
		   <el-form-item label="时间范围" v-if="active==2">
		         <el-date-picker
		            v-model="search_key.timeSpan"
		            type="datetimerange"
		            range-separator="至"
		            start-placeholder="开始日期"
		            end-placeholder="结束日期"
		            value-format="yyyy-MM-dd HH:mm:ss">
		        </el-date-picker>
		     </el-form-item>
	       <el-button type="primary" @click="getData()">查询</el-button>
	   </el-form>
	  <el-table
		v-if="active == 1"
	     :data="tableData" 
		 row-key="id"
		 :default-expand-all="false" 
		 :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
	     border>
		 
		<el-table-column
		    prop="name"
		    label="资产分类">
		</el-table-column>
	   <!--  <el-table-column
	         prop="dept_name"
	         label="分摊部门">
	     </el-table-column> -->
	      <el-table-column
	         prop="asset_count"
	         label="资产数量">
	     </el-table-column>  
	     <el-table-column 
	         prop="original_value"
	         label="原值">
			  <template slot="header" slot-scope="scope">
				 <el-tooltip class="item" effect="dark" content="固定资产的购置及安装运输费用构成固定资产的原值，是企业计算固定资产折旧的依据" placement="top">
						<span>原值<i class="el-icon-question" style="font-size:14px;padding-left:10px;"></i> </span>
				</el-tooltip>
			  </template>
	     </el-table-column>
		 <el-table-column
		     prop="accumulative_discount"
		     label="累计折旧">
		 </el-table-column>
		 <el-table-column
		     prop="discount"
		     label="本期折旧"> 
		 </el-table-column>
		 <el-table-column
		     prop="net_value" 
		     label="净值">
			 <template slot="header" slot-scope="scope">
			        <el-tooltip class="item" effect="dark" content="资产原值减去累计折旧额后的余额" placement="top">
						 <span>净值 <i class="el-icon-question" style="font-size:14px;padding-left:10px;"></i></span>
						
			           </el-tooltip>
			       </template>
			<!-- <template slot-scope="scope">
				 <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
				      <el-button>{{scope.row.new_value}}</el-button>
				    </el-tooltip>
			 </template> -->
		 </el-table-column>
	  </el-table>
	  <el-table
	  		v-if="active == 2"
	     :data="tableData" 
	     border>	  		 
	  		<el-table-column
	  		    prop="asset_code"
	  		    label="资产编码">
	  		</el-table-column>
	     <el-table-column 
	         prop="current_dprn_prices"
	         label="本期折旧">
	     </el-table-column>
	  		 <el-table-column
	  		     prop="salvage_rate"
	  		     label="残值率(%)">
	  		 </el-table-column>
	  		 <el-table-column
	  		     prop="first_calc_date"
	  		     label="首次计提月份">
	  		 </el-table-column>
		</el-table>
 </div>
</template>

<script>
export default {
	 inject:['reload'],
    data() {
        return {
			search_key:{
				keyword:'',
				timeSpan:''
			},
			active:1,
			tableData:[]
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){  
		    this.$api.post('AssetReport/dprn',{
				type:this.active,
				keyword:this.search_key.keyword,
				timeSpan:this.search_key.timeSpan
			},res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
					
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		handleClick(val){
			this.getData()
		}
    },
}
</script>
<style scoped lang="scss">
</style>