import { render, staticRenderFns } from "./Dprn.vue?vue&type=template&id=05f6aebc&scoped=true&"
import script from "./Dprn.vue?vue&type=script&lang=js&"
export * from "./Dprn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f6aebc",
  null
  
)

export default component.exports